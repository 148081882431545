nav {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  background-color: #222831;
  width: 65px;
  overflow: hidden;
  transition: width 0.2s linear;
  z-index: 10000;

  li {
    margin: 0 0 1rem 0;
    a {
      position: relative;
      color: white;
      font-size: 15px;
      display: table;
      width: 300px;
      padding: 10px;
      text-decoration: none;
      div {
        display: flex;
        align-items: center;

        .sidebar_img {
          position: relative;
          width: 25px;
          margin: 0 1.4rem 0 0.5rem;
        }
        span {
          position: relative;
        }
      }
    }
  }

  li:first-child {
    margin: 1rem 0 3rem 0;
  }

  a:hover {
    background: gray;
  }
}

nav:hover {
  width: 250px;
  transition: all 0.5s ease;
}

.logout {
  position: absolute;
  bottom: 20px;
}
