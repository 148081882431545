.custom-pagination {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  .pagination-nav {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      .numberInputOnly::-webkit-outer-spin-button,
      .numberInputOnly::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .numberInputOnly {
        -moz-appearance: textfield;
        appearance: textfield;
      }
      input {
        text-align: center;
        outline: none;
        margin-left: 0.4rem;
        width: 70px;
        color: black;
      }
      .spaceAfterOf {
        margin-right: 0.3rem;
      }
      font-size: 15px;
      color: black;
      margin: 0 1rem;
    }

    button {
      outline: none;
      background: transparent;
      border: none;
      font-size: 15px;
    }

    .pagerSingleArrowLeftBtn {
      margin-left: 0.4rem;
    }

    .pagerSingleArrowRightBtn {
      margin-right: 1rem;
    }
    button::first-letter {
      margin-right: -8px;
    }
  }
}
