@import '../../../../global.scss';

.createProjectContainer {
  width: 100%;
  .createProjectForm {
    // padding-left: 1rem;
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .archiveProject {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        .archiveCheckbox {
          margin-right: 0.3rem;
        }
        label {
          font-size: 19px;
          font-weight: bold;
        }
      }
    }

    .createProjectFormBox {
      border: 2px solid black;
      padding: 2rem;
    }

    .createProjectFormRow {
      display: flex;
      justify-content: space-between;

      .row {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 0.5rem;
        label {
          font-size: small;
          font-weight: bold;
        }
        input[type='text'],
        input[type='date'],
        select,
        textarea {
          border: 1px solid black;
          border-radius: 0;
          width: 90%;
          margin-top: 1rem;
          padding: 0 0.3rem;
          outline: none;
          height: 30px;
        }
        select {
          width: 31%;
        }
        select:disabled {
          color: black;
          background-color: white;
          border: 1px solid black;
        }
        select:disabled option {
          color: black;
        }
      }
    }
  }
  .createProjectBtn {
    text-align: center;
    margin-top: 0.5rem;
    padding: 1rem 0 3rem 0;
    button {
      background-color: $primary-yellow-color;
      padding: 1rem 3rem;
      border: none;
      text-align: center;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.projectIdInputBoxWithSuggestions {
  position: relative;
  width: 100%;
  .showProjResults {
    // border: 1px solid gray;
    // position: absolute;
    // width: 90%;
    // margin: 0 auto;
    // top: 62px;
    // max-height: 120%;
    // overflow-y: auto;
    // background-color: white;
    // padding: 0 0.3rem;
    // font-size: 12px;
    // box-shadow: 2px 2px 6px gray;
    position: absolute;
    top: 62px;
    width: 93.7%;
    background-color: white;
    border: 1px solid #ccc;
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 100px;
    overflow-y: auto;
    font-size: 13px;
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgb(163, 162, 162);
    li {
      padding: 8px;
      cursor: pointer;
    }

    li:hover {
      background-color: #eee;
    }
  }
  // .resultItems {
  //   display: grid;
  //   cursor: pointer;
  // }
}
