.heading_container {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.hmain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  img {
    width: 30px;
    margin-left: 0.5rem;
  }
}

.app_title {
  font-size: 20px;
  font-weight: bold;
  color: chocolate;
}

.greet_user {
  font-weight: bolder;
  font-size: 20px;
  color: rgb(24, 24, 86);
  span {
    color: blue;
  }
  span:last-child {
    color: tomato;
  }
}

.underlines {
  height: 1px;
  background: rgb(114, 110, 110);
  border-radius: 2px;
}
