@import "../../../../../global.scss";

.history {
  box-shadow: 1px 1px 4px gray;

  .historySearchInputBox {
    background-color: $primary-darkblue-color;
    padding: 1.2rem;

    div {
      width: 26%;
      display: flex;
      align-items: center;
      background-color: white;
      border-radius: 7px;
      i {
        font-size: 25px;
        margin-left: 0.3rem;
      }
      input {
        width: 100%;
        outline: none;
        border: none;
        border-radius: 7px;
        padding: 0.5rem;
      }
    }
  }

  .historyBodyContainer {
    div:first-child {
      box-shadow: 1px 1px 4px gray;
    }
    .historyBtn {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      color: white;
      margin-top: 0.4rem;
      background-color: #9e6703db;
      cursor: pointer;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          margin-left: 0.5rem;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
    .errorMessage {
      text-align: center;
      color: tomato;
      padding: 1%;
    }
  }
}

.historyTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  font-size: small;
}

.historyTable td,
.historyTable thead {
  padding: 10px;
}

.historyTable tbody td {
  max-width: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.historyTable thead td {
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: left;
  background-color: rgba(125, 87, 25, 0.5);
  color: white;
}

.historyTable tbody select {
  border: 1px solid #ccc;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  padding: 0.3rem;
  width: 80%;
}

.historyTable tbody input[type="text"] {
  width: 80%;
  border: 1px solid #ccc;
  outline: none;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

.historyTable tbody input[type="text"]:focus {
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 0 0 2px rgba(0, 0, 0, 0.3) inset;
}

.historyTable tbody input[type="radio"] {
  margin-left: 1rem;
}

.historyTable .assessmentBody {
  text-align: left;
}
