@import "../../../../global.scss";

form {
  .projectHealthSectionMain {
    .projectHealthSection {
      width: 70%;
      margin: 2rem auto 0.7rem auto;
      // background-color: $primary-yellow-color;
      // background-image: linear-gradient(to right, #ff9800, #2c4e80, #1a4d2e);
      background-color: #028391;
      p {
        padding: 1rem 0 0 1.5rem;
        color: white;
        font-weight: bold;
      }
      .projectHealthInputContainer {
        display: flex;
        padding: 1rem 0 2rem 1.5rem;
        div {
          display: flex;
          flex-direction: column;
          flex: 1;
          margin-top: 1rem;
          label {
            font-size: small;
            font-weight: bold;
            color: white;
          }
          input {
            border: 1px solid black;
            border-radius: 0;
            width: 90%;
            margin-top: 1rem;
            padding: 0 0.3rem;
            outline: none;
            height: 30px;
          }
          select {
            width: 93%;
            border: 1px solid black;
            border-radius: 0;
            margin-top: 1rem;
            padding: 0 0.3rem;
            outline: none;
            height: 30px;
          }
        }
      }
    }
  }

  .newAssessmentTabSection {
    padding: 1.5rem;
    .tabsContainer {
      display: flex;
      p {
        cursor: pointer;
        padding: 0.5rem 1rem;
      }
    }
  }
  .newAssessmentForm {
    padding-left: 1rem;
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .archiveProject {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      div {
        // display: flex;
        justify-content: center;
        align-items: center;
        .archiveCheckbox {
          margin-right: 0.3rem;
        }
        label {
          font-size: 19px;
          font-weight: bold;
        }
      }
    }

    .newAssessmentFormRow {
      display: flex;
      justify-content: space-between;

      .row {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 1rem;
        label {
          font-size: small;
          font-weight: bold;
        }
        input[type="text"],
        input[type="date"],
        select,
        textarea {
          border: 1px solid black;
          border-radius: 0;
          width: 90%;
          margin-top: 1rem;
          padding: 0 0.3rem;
          outline: none;
          height: 30px;
        }
        select {
          width: 93%;
        }
      }
    }
  }
}

.newAssessmentFormBtn {
  text-align: center;
  margin-top: 1rem;
  padding: 1rem 0 3rem 0;
  display: flex;
  justify-content: space-evenly;
  button {
    background-color: $primary-yellow-color;
    padding: 1rem 3rem;
    border: none;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }
  .archivebtn {
    background-color: $primary-yellow-color;
    padding: 1rem 3rem;
    border: none;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
  }
}

.reAssessBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  input {
    color: white;
    border: none;
    background-color: #2f3a74;
    padding: 0.2rem 2rem;
    margin-bottom: 0.3rem;
    cursor: pointer;
  }
}

.hide {
  transform: translateY(-100px);
  overflow: hidden;
}

.projectIdInputBoxWithSuggestions {
  position: relative;
  width: 100%;
  .showReviewerResults {
    // border: 1px solid gray;
    // position: absolute;
    // width: 90%;
    // margin: 0 auto;
    // top: 62px;
    // max-height: 120%;
    // overflow-y: auto;
    // background-color: white;
    // padding: 0 0.3rem;
    // font-size: 12px;
    // box-shadow: 2px 2px 6px gray;
    position: absolute;
    top: 62px;
    width: 93.7%;
    background-color: white;
    border: 1px solid #ccc;
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 100px;
    overflow-y: auto;
    font-size: 13px;
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgb(163, 162, 162);
    li {
      padding: 8px;
      cursor: pointer;
    }

    li:hover {
      background-color: #eee;
    }
  }
  // .resultItems {
  //   display: grid;
  //   cursor: pointer;
  // }
}
.buttonEdit {
  border: none;
  font-size: 15px;
  padding: 1rem 3rem;
  background-color: #ff9800;
  cursor: pointer;
  margin-left: -24px;
}

.handleEditButton {
  padding-top: 10px;
}
