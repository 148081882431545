@import "../../../../global.scss";

.projectDetailsBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: 2rem auto 1rem;
  background-color: $primary-darkblue-color;
  color: white;
  cursor: pointer;
  .projectDetailsBar-div {
    display: flex;
    padding: 1.2rem;
    p:first-child {
      margin-right: 0.4rem;
    }
  }
  i {
    padding: 1rem;
    font-size: 25px;
    cursor: pointer;
  }
}
