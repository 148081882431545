#emailbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.email {
  border: 1px solid black;
  padding: 2rem;
}
