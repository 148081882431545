* {
      font-family: "Poppins", sans-serif;
}

.popup {
    display: flex;
    max-height: 520px;
    border-collapse: collapse;
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-collapse: collapse;
    text-align: center;
}
.popup-content{
    width: 700px;
}
   
.main{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    justify-content: center;
    flex-direction: column;
    display: block;
    overflow: hidden;
    justify-content: center;
    border-collapse: collapse;
}

.header{
   background-color: #2F3A74;
   color: white;
   width: 100%;
   height: 50px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-size: 14px;
   
   span:nth-child(1) {
      flex: 1;
      margin-left: 4%;
   }
}

.dropdownitems {
   display: flex;
   justify-content: flex-start;
   align-items: baseline;
   margin-left: 13%;
   span:nth-child(2){
      margin-top: 2%;
      margin-left: 20%;
      flex-grow: 1;
   }
 }
 .table1 th,
 .table1 td {
   padding: 8px;  
}
.dropdownitems1 {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-left: 20%;
}

.textopt{
   border: 0;
   width: 165.5%;
   margin-top: 10px;
   margin-left: 53%;
   padding: 8px;
   text-align: left;
   font-size: 14px;
}
.crossImg{
   flex-direction: row;
   width: 20px;
   cursor: pointer;
   padding-left: 30px;
 }

.addbutton{
   margin-left: 50px;
   padding: 10px;
   margin: 20px;
   background-color: #2F3A74;
}

 .savebutton{
    padding: 10px;
    margin: 20px;
    background-color: #2F3A74;
 }
 .add{
    color: #F4F0F0;
    background-color: #2F3A74;
    border: none;
    padding: 10px;
    padding-left: 50px;
    padding-right: 50px;
 }
 .save{
    color: #F4F0F0;
    background-color: #2F3A74;
    border: none;
    padding: 10px;
    padding-left: 50px;
    padding-right: 50px;
 }

 .boxradiofield {
   border: 0;
   width: 165%;
   padding: 8px;
   background-color: #F4F0F0;
   box-shadow: 0px 3px 2px #ccc; 
   text-align: left;
   font-size: 14px;
   margin-left: -73%;
}

.errorCode {
   color: red;
   font-size: 12px;
   margin-left: -55%;
   padding-top: 2%;
}



