@import "../../../../../global.scss";

.questions {
  box-shadow: 2px 2px 4px gray;
  .questionBtn {
    display: flex;
    justify-content: space-between;
    background-color: $primary-darkblue-color;
    cursor: pointer;
    color: white;
    padding: 0.7rem;
    // margin-bottom: 10px;
    box-shadow: 2px 2px 4px white;
    margin-top: 0.5 rem;
  }
  // .saveBtnContain {
  //   display: flex;
  //   justify-content: flex-end;
  .savebtn {
    border: none;
    outline: none;
    background-color: $primary-darkblue-color;
    padding: 0.5rem 2rem;
    color: white;
    margin: 1rem;
    cursor: pointer;
  }
  // }
}

.questionTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  font-size: small;
  .responseIsComplusory::after {
    content: "*";
    font-size: 20px;
    font-weight: bold;
    color: red;
  }
  input[type="text"] {
    width: 100%;
  }
  .checkbox {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    div {
      justify-content: center;
      align-items: center;
    }
  }
  .radio {
    display: flex;
    flex-direction: row;
    justify-content: center;
    div {
      margin-right: 1rem;
    }
    input[type="radio"] {
      margin-right: 0.3rem;
    }
  }
}

.questionTable td,
.questionTable thead {
  padding: 10px;
}

.questionTable tbody .summaryRow:nth-child(even) {
  background-color: #ddd;
}

.questionTable tbody td {
  // max-width: 20px;
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  overflow-y: auto;
  text-align: left;
}

.questionTable thead td {
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: left;
  background-color: #9e6703db;
  color: white;
}

.questionTable tbody select {
  border: 1px solid #ccc;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  padding: 0.3rem;
  width: 83%;
}

.questionTable tbody input[type="text"] {
  border: 1px solid #ccc;
  outline: none;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  width: 80%;
}

.questionTable tbody input[type="text"]:focus {
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 0 0 2px rgba(0, 0, 0, 0.3) inset;
}
