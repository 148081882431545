.observations-overlay{
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.observations-content{
    margin:0 auto;
    background: #fff;
    padding:20px;
    border-radius: 10px;
    width: 50%;
    max-width: 600px;
    overflow-y: auto;
    max-height: 80%;
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
    position: relative;
    box-sizing: border-box;
}

.observation-close-button{
    position: absolute;
    top: 10px;
    right: 10px;
    //margin-top: 1%;
    //margin-right: 2%;
    font-size: 24px;
    cursor: pointer;
    user-select: none;
}

.observations-text{
    white-space: pre-wrap;
}