@import "./global.scss";

* {
  margin: 0;
  padding: 0;
  font-family: $arial-font-family;
}

button:disabled {
  opacity: 60%;
}

input:focus{
  outline: none;
}

.container {
  display: flex;
  margin: 0 0 0 65px;
  height: 100vh;
  overflow-y: auto;
}

// headers project list, DU Project status
.app-pageHeader{
  font-weight: bold;
  margin: 2rem 0 1rem 0;
  font-size: 20px;
  color: $primary-darkblue-color;
}

// dashboard, leadership side margin
.app-pageSize {
  width: 90%;
  margin: 0 auto;
}

.app-closebutton i{
  text-align: right;
  padding:0 10px 0 0;
  font-size: 25px;
}
